<template>
    <div class="preview_area show_sidebar" v-if="modelValue" :class="{ active : modelValue }">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <ul class="tabs_btn">
                <li :class="tab === 'playbook' ? 'active' : ''" @click="tab = 'playbook'">Playbooks</li>
                <li :class="tab === 'page' ? 'active' : ''" @click="tab = 'page'">Pages</li>
                <li :class="tab === 'form' ? 'active' : ''" @click="tab = 'form'">Forms</li>
                <li :class="tab === 'progress-tracking' ? 'active' : ''" @click="tab = 'progress-tracking'">Client Tracking</li>
                <li :class="tab === 'habit-tracking' ? 'active' : ''" @click="tab = 'habit-tracking'">Habit Tracking</li>
            </ul>
            <div class="tabs_content">
                <div class="loader_area2" v-if="contentLoader"><quote-loader/></div>
                <button type="button" class="toggle_bar" @click="toggleSectionbar" v-show="!contentLoader"><img src="@/assets/images/bar-left.svg" alt=""></button>
                <Form @submit="handlePlaybookContentSubmit" v-slot="{ errors }" class="setting-form" v-show="!contentLoader">
                    <div class="content_area" v-show="tab === 'playbook'">
                        <default-content-form v-model="playbookForm" :errors="errors" :tab="tab" form-type="playbook" ref="playbook-form" :pass-data="getData"/>
                    </div>
                    <div class="content_area" v-show="tab === 'page'">
                        <default-content-form v-model="pageForm" :errors="errors" :tab="tab" form-type="page" ref="page-form" :pass-data="getData"/>
                    </div>
                    <div class="content_area" v-show="tab === 'form'">
                        <default-content-form v-model="formForm" :errors="errors" :tab="tab" form-type="form" ref="form-form" :pass-data="getData"/>
                    </div>
                    <div class="content_area" v-show="tab === 'progress-tracking'">
                        <default-content-form v-model="progressTrackingForm" :errors="errors" :tab="tab" form-type="progress-tracking" ref="progress-tracking-form" :pass-data="getData"/>
                    </div>
                    <div class="content_area" v-show="tab === 'habit-tracking'">
                        <default-content-form v-model="habitTrackingForm" :errors="errors" :tab="tab" form-type="habit-tracking" ref="habit-tracking-form" :pass-data="getData"/>
                    </div>
                    <button type="button" class="preview_btn" @click="preview = true">Preview</button>
                    <div class="action_wpr">
                        <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal()">Exit</button>
                        <button :disabled="loader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
                    </div>
                </Form>
            </div>

            <div class="preview_content" :class="{ show : preview }" v-if="form !== '' && (tab != 'progress-tracking' && tab != 'habit-tracking')">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="preview_playbook" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="preview_playbook" v-model="fullPreview" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <div class="cell">
                    <div class="content_area">
                        <div class="dashboard_content">
                            <div class="client_card border-0 mb-5">
                                <div class="card_header" v-if="form.display_header" :style="`background: ${form.header_bgcolor}; color: ${form.header_textcolor};`">
                                    <nav>
                                        <img class="logo_img" :src="form.company_logo && form.company_logo != 'null' ? form.company_logo : require('@/assets/images/thumb/default-logo.svg')" alt="logo">
                                        <a><h4>Dashboard</h4></a>
                                        <!-- <a><h4>{{ form.company_branding }}</h4></a> -->
                                    </nav>
                                    <div class="user_box" @click="accountDrop = !accountDrop">
                                        <img :src="form.author ? form.author : (user.profile_pic ? user.profile_pic : require('@/assets/images/table-user.png'))" @error="setDefaultAvatar">
                                        <div class="user_info">
                                            <h5 :style="`color: ${form.header_textcolor};`">{{ user.full_name }}</h5>
                                        </div>
                                        <ul class="account_drop" :class="{active : accountDrop}">
                                            <li><i class="fas fa-home"></i> Primary Location</li>
                                            <li><i class="far fa-user"></i> Profile Info</li>
                                            <li><i class="far fa-file"></i> My Files</li>
                                            <li><i class="fas fa-sign-out-alt"></i> Logout</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="card_body cover-image-sm" v-if="form.display_cover && form.has_small_screen_cover">
                                    <picture v-if="(form.cover_type == 2 || form.cover_type == 3)">
                                        <source v-if="form.has_small_screen_cover && form.ep_logo_small != null && form.ep_logo_small != '' && form.ep_logo_small != 'null'" :srcset="`${form.ep_logo_small}`" media="(max-width: 768px)">
                                        <img v-if="form.has_small_screen_cover" :src="form.ep_logo_small && form.ep_logo_small != 'null' ? form.ep_logo_small : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="" class="banner_img">
                                        <!-- <img  :src="form.has_small_screen_cover && form.ep_logo_small ? form.ep_logo_small : (form.ep_logo? form.ep_logo: require('@/assets/images/thumb/default-sm-cover.svg'))" alt="" class="banner_img"> -->
                                    </picture>
                                    <span v-if="form.cover_type == 1" class="overlay" :style="`background-color: ${form.cover_background_color ? form.cover_background_color : form.opac_overlay_color};`"></span>
                                    <span v-else-if="form.cover_type == 2 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                                    <span v-else-if="form.cover_type == 3 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>

                                    <div class="banner_content">
                                        <h4 v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${ (form && form.headline_setting && form.headline_setting.font_size) ? form.headline_setting.font_size / 3 : ''}px; line-height: ${(form && form.headline_setting && form.headline_setting.font_size) ? (parseInt(form.headline_setting.font_size) + 20) / 3: ''}px; font-weight: ${form && form.headline_setting && form.headline_setting.font_weight}; font-family: ${form && form.headline_setting && form.headline_setting.font_family}; color: ${form && form.headline_setting && form.headline_setting.font_color ? form.headline_setting.font_color : ''}; ${form && form.headline_setting && form.headline_setting.has_shadow ? 'text-shadow: ' + form.headline_setting.shadow_x +'px ' + form.headline_setting.shadow_y + 'px ' + form.headline_setting.shadow_blur + 'px ' + form.headline_setting.shadow_color : 'none'}`">{{ form.headline }}</h4>
                                        <h1 v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form && form.subheadline_setting ? form.subheadline_setting.font_size / 3 : ''}px; line-height: ${form && form.subheadline_setting ? (parseInt(form.subheadline_setting.font_size) + 20) / 3 : ''}px; font-weight: ${form && form.subheadline_setting ? form.subheadline_setting.font_weight : ''}; font-family: ${form && form.subheadline_setting ? form.subheadline_setting.font_family : ''}; color: ${form && form.subheadline_setting && form.subheadline_setting.font_color  ? form.subheadline_setting.font_color : ''}; ${form && form.subheadline_setting && form.subheadline_setting.has_shadow ? 'text-shadow: ' + form.subheadline_setting.shadow_x +'px ' + form.subheadline_setting.shadow_y + 'px ' + form.subheadline_setting.shadow_blur + 'px ' + form.subheadline_setting.shadow_color : 'none'}`">{{ form.sub_headline }}</h1>
                                    </div>
                                </div>
                            </div>
                            <ul class="mobile_menu px-3" v-if="tab == 'playbook' || tab == 'page' || tab == 'form'">
                                <!-- <li v-if="tab == 'form' && get_started" @click="get_started = 0;"><i class="fas fa-angle-left"></i>Back</li> -->
                                <!-- <li @click="openSlide('offer')" v-if="tab == 'playbook' || tab == 'page'">
                                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.82682 7.14098C4.55389 5.71402 5.71402 4.55388 7.14098 3.82682C8.09748 3.33947 9.09148 3.15918 10.0908 3.07753C11.0377 3.00018 12.1873 3.0002 13.5199 3.00025L25.1389 3.00013C26.1675 2.99917 27.0742 2.99833 27.9552 3.20979C28.7289 3.39556 29.4686 3.70199 30.1471 4.11777C30.9196 4.59109 31.5602 5.23292 32.2869 5.96092L51.8948 25.569C53.3337 27.0079 54.5213 28.1953 55.4096 29.2418C56.3322 30.3285 57.0795 31.4284 57.5052 32.7379C58.1649 34.7688 58.1649 36.9564 57.5052 38.9873C57.0795 40.2967 56.3322 41.3966 55.4096 42.4833C54.5213 43.5298 53.3337 44.7172 51.8948 46.156L46.1561 51.8948C44.7172 53.3337 43.5296 54.5213 42.4833 55.4096C41.3966 56.3322 40.2967 57.0797 38.987 57.5052C36.9564 58.1649 34.7688 58.1649 32.7379 57.5052C31.4285 57.0797 30.3286 56.3322 29.2418 55.4096C28.1953 54.5213 27.008 53.3337 25.569 51.8948L5.96092 32.2869C5.23292 31.5601 4.59107 30.9196 4.11778 30.1473C3.70197 29.4688 3.39557 28.7289 3.20979 27.9551C2.99832 27.0744 2.99917 26.1674 3.00013 25.1389L3.00026 13.5199C3.00021 12.1873 3.00017 11.0377 3.07754 10.0909C3.15919 9.09148 3.33947 8.09748 3.82682 7.14098ZM20.6954 15.6393C17.9032 15.6393 15.6396 17.9029 15.6396 20.6951C15.6396 23.4873 17.9032 25.7508 20.6954 25.7508C23.4876 25.7508 25.7511 23.4873 25.7511 20.6951C25.7511 17.9029 23.4876 15.6393 20.6954 15.6393Z" fill="#999999"/>
                                    </svg>
                                    Offer
                                </li> -->
                                <li @click="openSlide('instructor')" v-if="form.display_coach" :class="{'ml-auto' : tab == 'form' || tab == 'page'}">
                                    <svg width="50" height="54" viewBox="0 0 50 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.0717 43.5574C7.3429 36.5812 15.646 32.0607 25.0001 32.0607C34.3541 32.0607 42.6572 36.5812 47.9283 43.5574C47.9521 43.5889 47.9761 43.6207 48.0001 43.6522C48.4533 44.2517 48.9393 44.8943 49.2866 45.5144C49.7068 46.2649 50.0186 47.1329 49.9991 48.1888C49.9836 49.0373 49.7266 49.8319 49.4143 50.4592C49.102 51.0865 48.6232 51.7705 47.9555 52.2947C47.0648 52.9936 46.0952 53.241 45.2338 53.3441C44.4783 53.4347 43.5827 53.4347 42.6824 53.4344C42.6417 53.4344 42.6011 53.4344 42.5605 53.4344H7.43943C7.39885 53.4344 7.35827 53.4344 7.31771 53.4344C6.41734 53.4347 5.5218 53.4347 4.76613 53.3441C3.9048 53.241 2.9352 52.9936 2.04467 52.2947C1.37687 51.7705 0.898042 51.0865 0.585771 50.4592C0.273501 49.8319 0.0163741 49.0373 0.000851407 48.1888C-0.0184651 47.1329 0.293084 46.2649 0.713453 45.5144C1.06072 44.8943 1.54671 44.2517 1.99991 43.6522C2.02396 43.6207 2.04787 43.5889 2.0717 43.5574Z" fill="#999999"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3047 14.6945C10.3047 6.57893 16.8836 0 24.9992 0C33.1146 0 39.6937 6.57893 39.6937 14.6945C39.6937 22.8101 33.1146 29.3889 24.9992 29.3889C16.8836 29.3889 10.3047 22.8101 10.3047 14.6945Z" fill="#999999"/>
                                    </svg>
                                    Coach
                                </li>
                                <li @click="openSlide('menu')" v-if="tab == 'playbook'" class="ml-auto">
                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M47.8032 2.19668C50.7321 5.12558 50.7321 9.8743 47.8032 12.8032C44.8742 15.7321 40.1257 15.7321 37.1967 12.8032C34.2678 9.8743 34.2678 5.12558 37.1967 2.19668C40.1257 -0.732226 44.8742 -0.732226 47.8032 2.19668Z" fill="#999999"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M47.4997 22.0136C47.4997 20.9916 47.4997 20.4806 47.3337 20.1916C47.1672 19.9012 46.9979 19.7579 46.6839 19.6416C46.3714 19.5258 45.7739 19.6261 44.5792 19.8266C40.728 20.4728 36.6325 19.3103 33.661 16.3387C30.6893 13.3671 29.5268 9.27152 30.173 5.42042C30.3735 4.22555 30.4738 3.62813 30.358 3.31563C30.2418 3.00176 30.0985 2.83249 29.808 2.66586C29.519 2.49994 29.008 2.49994 27.986 2.49994H14.3966C12.3843 2.49991 10.7233 2.49989 9.37038 2.61041C7.96514 2.72524 6.6734 2.97164 5.46006 3.58988C3.57845 4.5486 2.04866 6.07839 1.08994 7.96C0.471698 9.17332 0.225299 10.4651 0.1105 11.8703C-4.93877e-05 13.2233 -2.42479e-05 14.8841 7.51906e-07 16.8965V35.603C-2.42479e-05 37.6154 -4.93877e-05 39.2762 0.1105 40.6292C0.225299 42.0344 0.471698 43.3261 1.08994 44.5396C2.04866 46.4211 3.57845 47.9509 5.46006 48.9096C6.6734 49.5279 7.96514 49.7743 9.37038 49.8891C10.7233 49.9996 12.3842 49.9996 14.3966 49.9996H33.103C35.1155 49.9996 36.7762 49.9996 38.1292 49.8891C39.5345 49.7743 40.8262 49.5279 42.0397 48.9096C43.9212 47.9509 45.4509 46.4211 46.4097 44.5396C47.0279 43.3261 47.2744 42.0344 47.3892 40.6292C47.4997 39.2762 47.4997 37.6154 47.4997 35.603V22.0136ZM9.99993 27.4998C9.99993 26.119 11.1192 24.9998 12.4999 24.9998H32.4998C33.8805 24.9998 34.9997 26.119 34.9997 27.4998C34.9997 28.8805 33.8805 29.9997 32.4998 29.9997H12.4999C11.1192 29.9997 9.99993 28.8805 9.99993 27.4998ZM9.99993 37.4997C9.99993 36.1189 11.1192 34.9997 12.4999 34.9997H27.4998C28.8805 34.9997 29.9998 36.1189 29.9998 37.4997C29.9998 38.8804 28.8805 39.9997 27.4998 39.9997H12.4999C11.1192 39.9997 9.99993 38.8804 9.99993 37.4997Z" fill="#999999"/>
                                    </svg>
                                    Menu
                                </li>
                            </ul>
                            <div class="left_side" v-if="tab == 'playbook'">
                                <div class="section_item p-0 mb-3">
                                    <h3 class="m-0">
                                        Playbook Contents
                                    </h3>
                                </div>
                                <div class="step_card">
                                    <div class="thumb">
                                        <img src="@/assets/images/thumb/default-step-cover.svg" alt="">
                                    </div>
                                    <div class="step_info pointer">
                                        <h5>Getting Started</h5>
                                        <p>This is where your getting started content goes</p>
                                    </div>
                                </div>
                                <div class="step_card">
                                    <div class="thumb">
                                        <img src="@/assets/images/thumb/default-step-cover.svg" alt="">
                                    </div>
                                    <div class="step_info pointer">
                                        <h5>Week 1</h5>
                                        <p>This is where your week 1 content goes...</p>
                                    </div>
                                </div>
                                <div class="step_card">
                                    <div class="thumb">
                                        <img src="@/assets/images/thumb/default-step-cover.svg" alt="">
                                    </div>
                                    <div class="step_info pointer">
                                        <h5>Week 2</h5>
                                        <p>This is where your week 2 content goes...</p>
                                    </div>
                                </div>
                                <div class="step_card">
                                    <div class="thumb">
                                        <img src="@/assets/images/thumb/default-step-cover.svg" alt="">
                                    </div>
                                    <div class="step_info pointer">
                                        <h5>Keep Going</h5>
                                        <p>Add as many training steps as you desire!</p>
                                    </div>
                                </div>
                                <div class="section_item faqs">
                                    <h3>Playbook FAQ's</h3>
                                    <ul class="faq_list">
                                        <li class="small-preview" @click="faqAcc($event)">
                                            <h5 class="faq-acc-header">How do I get started? <i class="fas fa-angle-down"></i></h5>
                                            <p>This is where you can post your playbook FAQ questions...</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="left_side" v-if="tab == 'page'">
                                <div class="content_details">
                                    <div class="section_item media_title bord-t">
                                        <h3 class="m-0">
                                            Media Headline
                                        </h3>
                                    </div>
                                    <div class="section_item video_wpr p-0 b-round">
                                        <img src="@/assets/images/thumb/video-placeholder.svg" />
                                    </div>
                                    <div class="section_item media_title py-3">
                                        <h3 class="m-0">
                                            Content Headline
                                        </h3>
                                    </div>
                                    <div class="section_item p-0 mb-3">
                                        <p class="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    </div>
                                </div>
                                <div class="button_list" v-if="form.cta_button.display">
                                    <button class="justify-content-center pointer" :style="`color : ${ form.cta_button && form.cta_button.btn_text_color ? form.cta_button.btn_text_color : '' }; background-color: ${ form.cta_button && form.cta_button.btn_color ? form.cta_button.btn_color : '' };`" type="button">{{ form.cta_button && form.cta_button.text ? form.cta_button.text : '' }}</button>
                                </div>
                            </div>
                            <div class="left_side" v-if="tab == 'form'">
                                <div class="progress_sec progress_form" v-if="form.display_progressbar">
                                    <h3>Questions Completed</h3>
                                    <div class="status mb-1 mt-2"><span :style="`background-color: ${form.progressbar_color ? form.progressbar_color : '#2f7eed'}; width:100%;`"></span></div>
                                </div>
                                <!-- <template v-if="!get_started">
                                    <div class="section_item video_wpr p-0">
                                        <img src="@/assets/images/thumb/video-placeholder.svg" />
                                    </div>
                                    <div class="get_start">
                                        <button class="primary_btn" @click="get_started = 1;" :style="`color : ${ form.cta_button && form.cta_button.btn_text_color ? form.cta_button.btn_text_color : '#fff' }; background-color: ${ form.cta_button && form.cta_button.btn_color ? form.cta_button.btn_color : '#2c3e50' };`" type="button">{{ form.cta_button && form.cta_button.text ? form.cta_button.text : '' }}</button>
                                    </div>
                                </template> -->
                                <template v-if="get_started">
                                    <h3 class="title">Please answer the following:</h3>
                                    <div class="edit_section">
                                        <div class="quest_wpr">
                                            <h5><label class="q_index">1</label>Do you love your new program?</h5>
                                            <ul class="question-options">
                                                <li>
                                                    <span class="radio_box"></span>
                                                    <p>Absolutely!</p>
                                                </li>
                                                <li>
                                                    <span class="radio_box"></span>
                                                    <p>Sort of..</p>
                                                </li>
                                                <li>
                                                    <span class="radio_box"></span>
                                                    <p>Not so much</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="quest_wpr">
                                            <h5><label class="q_index">2</label>How can we improve your experience?</h5>
                                            <div class="setting_wpr mt-2">
                                                <div class="form_grp py-2">
                                                    <div class="group_item">
                                                        <div class="field_wpr mb-3">
                                                            <textarea placeholder="Content goes here.." disabled></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="submit_btn">
                                        <button class="primary_btn" type="button" style="background: #d4d4d4; color: #2c3e50;">SUBMIT</button>
                                        <div class="info text-center">Your submission is encrypted, secure, and confidential</div>
                                    </div>
                                </template>
                            </div>
                            <div class="right_side" v-if="tab == 'playbook'">
                                <div v-if="tab == 'playbook' && (form.recipient_ask.display == 1 || form.recipient_understand.display == 1 || form.recipient_complete.display == 1)" class="section_item action_sec">
                                    <h3>Additional Actions</h3>
                                    <ul class="button_list m-0 mb-1">
                                        <li v-if="form.recipient_ask.display == 1">
                                            <button type="button" :style="`background-color: ${ form.recipient_ask.button_color }; color: ${ form.recipient_ask.button_txt_color };`">
                                                <i class="fas fa-plus"></i>{{ form.recipient_ask.button_text }}
                                            </button>
                                        </li>
                                        <li v-if="form.recipient_understand.display == 1">
                                            <button type="button" :style="`background-color: ${ form.recipient_understand.button_color }; color: ${ form.recipient_understand.button_txt_color };`">
                                                <i class="fas fa-plus"></i>{{ form.recipient_understand.button_text }}
                                            </button>
                                        </li>
                                        <li v-if="form.recipient_complete.display == 1">
                                            <button type="button" :style="`background-color: ${ form.recipient_complete.button_color }; color: ${ form.recipient_complete.button_txt_color };`">
                                                <i class="fas fa-plus"></i>{{ form.recipient_complete.button_text }}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <footer class="dashboard_footer" v-show="form.display_footer" :style="`background: ${form.footer_bgcolor};`">
                            <ul>
                                <li class="pointer" v-if="form.has_footer_facebook"><a :href="form.footer_facebook" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-facebook-f"></i></a></li>
                                <li class="pointer" v-if="form.has_footer_twitter"><a :href="form.footer_twitter" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-twitter"></i></a></li>
                                <li class="pointer" v-if="form.has_footer_instagram"><a :href="form.footer_instagram" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-instagram"></i></a></li>
                            </ul>
                            <h4 :style="`color: ${form.footer_textcolor};`">© {{ form.footer_company }}</h4>
                            <p>
                                <a href="javascript:void(0);" :style="`color: ${form.footer_textcolor};`">Terms</a>
                                <a href="javascript:void(0);" :style="`color: ${form.footer_textcolor};`">Privacy</a>
                                <span v-html="form.white_label"></span>
                            </p>
                        </footer>
                    </div>
                    <!-- Instructor & Step listing -->
                    <div class="slide_box_container" :style="`pointer-events:${instructorDetails || steplistDetails || offerDetails ? 'all' : 'none'}`">
                        <div class="slide_box" :class="{'active' : steplistDetails}" v-if="tab == 'playbook'">
                            <button class="close_btn" @click="steplistDetails = false;"><i class="fas fa-times"></i></button>
                            <div class="box_container">
                                <div class="step_listing border-0 p-0">
                                    <div class="progress_sec" v-if="tab == 'playbook' && form.display_progressbar">
                                        <h3>Steps Completed</h3>
                                        <div class="status mb-1 mt-2"><span :style="`background-color: ${form.progressbar_color ? form.progressbar_color : '#2f7eed'}; width:100%;`"></span></div>
                                    </div>
                                    <h3 class="mb-4">Course Content</h3>
                                    <ul>
                                        <li class="step_card">
                                            <div class="thumb">
                                                <img src="@/assets/images/thumb/default-step-cover.svg" alt="">
                                            </div>
                                            <div class="step_info pointer">
                                                <h5>Getting Started</h5>
                                                <p>This is where your getting started content goes</p>
                                            </div>
                                        </li>
                                        <li class="step_card">
                                            <div class="thumb">
                                                <img src="@/assets/images/thumb/default-step-cover.svg" alt="">
                                            </div>
                                            <div class="step_info pointer">
                                                <h5>Week 1</h5>
                                                <p>This is where your week 1 content goes...</p>
                                            </div>
                                        </li>
                                        <li class="step_card">
                                            <div class="thumb">
                                                <img src="@/assets/images/thumb/default-step-cover.svg" alt="">
                                            </div>
                                            <div class="step_info pointer">
                                                <h5>Week 2</h5>
                                                <p>This is where your week 2 content goes...</p>
                                            </div>
                                        </li>
                                        <li class="step_card">
                                            <div class="thumb">
                                                <img src="@/assets/images/thumb/default-step-cover.svg" alt="">
                                            </div>
                                            <div class="step_info pointer">
                                                <h5>Keep Going</h5>
                                                <p>Add as many training steps as you desire!</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="slide_box" :class="{'active' : instructorDetails}" v-if="form.display_coach">
                            <button class="close_btn" @click="instructorDetails = false;"><i class="fas fa-times"></i></button>
                            <div class="box_container">
                                <div class="instructor" v-if="form.display_coach">
                                    <h3>{{ form.coach_headline ? form.coach_headline : 'Coach Headline' }}</h3>
                                    <div class="user_box">
                                        <img :src="form.coach_image && form.coach_image != 'null' ? form.coach_image : require('@/assets/images/customer.svg')" alt="">
                                        <div class="user_info">
                                            <h3>{{ form.coach_name ? form.coach_name : 'Joe Trainer' }}</h3>
                                            <h5>{{ form.coach_title ? form.coach_title : 'PHD, CSCS' }}</h5>
                                        </div>
                                    </div>
                                    <p v-html="form.coach_bio"></p>
                                </div>
                            </div>
                        </div>
                        <div class="slide_box" :class="{'active' : offerDetails}">
                            <button class="close_btn" @click="offerDetails = false;"><i class="fas fa-times"></i></button>
                            <div class="box_container">
                                <div class="section_item product_card" v-if="tab == 'playbook'">
                                    <h3>Offer Headline</h3>
                                    <img src="@/assets/images/thumb/offer-placeholder.svg" alt="product-image">
                                </div>
                                <div class="section_item product_card" v-if="tab == 'page'">
                                    <h3>Offer Headline</h3>
                                    <img src="@/assets/images/thumb/offer-placeholder.svg" alt="product-image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="form && form.public_settings && (tab == 'progress-tracking' || tab == 'habit-tracking')">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="full_preview" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="large">Full View</h5>
                            <input type="checkbox" id="full_preview" v-model="fullPreview" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <ul class="alt_prev">
                    <li @click="previewPage = 'main'" :class="{'active' : previewPage == 'main'}">{{ tab == 'progress-tracking' ?  'Tracking Page': 'Habits Page'}}</li>
                    <li @click="previewPage = 'thankyou'" :class="{'active' : previewPage == 'thankyou'}">Thank You</li>
                </ul>
                <div class="cell">
                    <div class="content_area">
                        <preview-content :preview-tab="tab" :preview-page="previewPage" :content-form="form" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <full-preview v-model="fullPreview" :tab="tab">
        <template v-if="form !== '' && (tab != 'progress-tracking' && tab != 'habit-tracking')">
            <div class="dashboard" :class="tab">
                <div class="client_card border-0">
                    <div class="card_header" v-if="form.display_header" :style="`background: ${form.header_bgcolor}; color: ${form.header_textcolor};`">
                        <div class="nav">
                            <!-- <h4>{{ form.company_branding }}</h4> -->
                            <img class="logo_img" :src="form.company_logo && form.company_logo != 'null' ? form.company_logo : require('@/assets/images/thumb/default-logo.svg')" alt="logo">
                            <h4>Dashboard</h4>
                        </div>
                        <div class="user_box" @click="accountDrop = !accountDrop">
                            <img :src="form.author ? form.author : (user.profile_pic ? user.profile_pic : require('@/assets/images/table-user.png'))" @error="setDefaultAvatar">
                            <div class="user_info">
                                <h5 :style="`color: ${form.header_textcolor};`">{{ user.full_name }}</h5>
                            </div>
                            <ul class="account_drop" :class="{active : accountDrop}">
                                <li><i class="fas fa-home"></i> Primary Location</li>
                                <li><i class="far fa-user"></i> Profile Info</li>
                                <li><i class="far fa-file"></i> My Files</li>
                                <li><i class="fas fa-sign-out-alt"></i> Logout</li>
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="card_body" :class="device != 'desk' ? ( device !== 'desk' ? 'cover-image-sm' : 'cover-image-md') :'cover-image'" v-if="form.display_cover"> -->
                    <div class="card_body" :class="device != 'desk' ? ( form.has_small_screen_cover ? 'cover-image-sm' : 'no_sm_cover') :'cover-image'" v-if="form.display_cover">
                        <picture v-if="(form.cover_type == 2 || form.cover_type == 3)">
                            <source v-if="form.has_small_screen_cover && form.ep_logo_small != null && form.ep_logo_small != '' && form.ep_logo_small != 'null'" :srcset="`${form.ep_logo_small}`" media="(max-width: 768px)">
                            <!-- <img v-if="device == 'desk'" :src="form.ep_logo? form.ep_logo: require('@/assets/images/thumb/default-cover.svg')" alt="" class="banner_img"> -->
                            <!-- <img v-else :src="form.has_small_screen_cover && form.ep_logo_small ? form.ep_logo_small : (form.ep_logo? form.ep_logo: require('@/assets/images/thumb/default-sm-cover.svg'))" alt="" class="banner_img"> -->
                            <img v-if="device == 'desk'" :src="form.ep_logo && form.ep_logo != 'null' ? form.ep_logo: require('@/assets/images/thumb/default-cover.svg')" alt="" class="banner_img">
                            <img v-if="device !== 'desk' && form.has_small_screen_cover" :src="form.ep_logo_small && form.ep_logo_small != 'null' ? form.ep_logo_small : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="" class="banner_img">
                        </picture>
                        <span v-if="form.cover_type == 1" class="overlay" :style="`background-color: ${form.cover_background_color ? form.cover_background_color : form.opac_overlay_color};`"></span>
                        <span v-else-if="form.cover_type == 2 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                        <span v-else-if="form.cover_type == 3 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>

                        <div class="banner_content" v-if="device == 'desk'">
                            <h4 v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${ (form && form.headline_setting && form.headline_setting.font_size) ? form.headline_setting.font_size : ''}px; line-height: ${(form && form.headline_setting && form.headline_setting.font_size) ? parseInt(form.headline_setting.font_size) + 20 : ''}px; font-weight: ${form && form.headline_setting && form.headline_setting.font_weight}; font-family: ${form && form.headline_setting && form.headline_setting.font_family}; color: ${form && form.headline_setting && form.headline_setting.font_color ? form.headline_setting.font_color : ''}; ${form && form.headline_setting && form.headline_setting.has_shadow ? 'text-shadow: ' + form.headline_setting.shadow_x +'px ' + form.headline_setting.shadow_y + 'px ' + form.headline_setting.shadow_blur + 'px ' + form.headline_setting.shadow_color : 'none'}`">{{ form.headline }}</h4>
                            <h1 v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form && form.subheadline_setting ? form.subheadline_setting.font_size : ''}px; line-height: ${form && form.subheadline_setting ? parseInt(form.subheadline_setting.font_size) + 20 : ''}px; font-weight: ${form && form.subheadline_setting ? form.subheadline_setting.font_weight : ''}; font-family: ${form && form.subheadline_setting ? form.subheadline_setting.font_family : ''}; color: ${form && form.subheadline_setting && form.subheadline_setting.font_color  ? form.subheadline_setting.font_color : ''}; ${form && form.subheadline_setting && form.subheadline_setting.has_shadow ? 'text-shadow: ' + form.subheadline_setting.shadow_x +'px ' + form.subheadline_setting.shadow_y + 'px ' + form.subheadline_setting.shadow_blur + 'px ' + form.subheadline_setting.shadow_color : 'none'}`">{{ form.sub_headline }}</h1>
                        </div>
                        <div class="banner_content" v-if="device == 'tab'">
                            <h4 v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${ (form && form.headline_setting && form.headline_setting.font_size) ? form.headline_setting.font_size / 2 : ''}px; line-height: ${(form && form.headline_setting && form.headline_setting.font_size) ? (parseInt(form.headline_setting.font_size) + 20) / 2 : ''}px; font-weight: ${form && form.headline_setting && form.headline_setting.font_weight}; font-family: ${form && form.headline_setting && form.headline_setting.font_family}; color: ${form && form.headline_setting && form.headline_setting.font_color ? form.headline_setting.font_color : ''}; ${form && form.headline_setting && form.headline_setting.has_shadow ? 'text-shadow: ' + form.headline_setting.shadow_x +'px ' + form.headline_setting.shadow_y + 'px ' + form.headline_setting.shadow_blur + 'px ' + form.headline_setting.shadow_color : 'none'}`">{{ form.headline }}</h4>
                            <h1 v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form && form.subheadline_setting ? form.subheadline_setting.font_size / 1.5 : ''}px; line-height: ${form && form.subheadline_setting ? (parseInt(form.subheadline_setting.font_size) + 20) / 2 : ''}px; font-weight: ${form && form.subheadline_setting ? form.subheadline_setting.font_weight : ''}; font-family: ${form && form.subheadline_setting ? form.subheadline_setting.font_family : ''}; color: ${form && form.subheadline_setting && form.subheadline_setting.font_color  ? form.subheadline_setting.font_color : ''}; ${form && form.subheadline_setting && form.subheadline_setting.has_shadow ? 'text-shadow: ' + form.subheadline_setting.shadow_x +'px ' + form.subheadline_setting.shadow_y + 'px ' + form.subheadline_setting.shadow_blur + 'px ' + form.subheadline_setting.shadow_color : 'none'}`">{{ form.sub_headline }}</h1>
                        </div>
                        <div class="banner_content" v-if="device == 'cell'">
                            <h4 v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${ (form && form.headline_setting && form.headline_setting.font_size) ? form.headline_setting.font_size / 3 : ''}px; line-height: ${(form && form.headline_setting && form.headline_setting.font_size) ? (parseInt(form.headline_setting.font_size) + 20) / 3: ''}px; font-weight: ${form && form.headline_setting && form.headline_setting.font_weight}; font-family: ${form && form.headline_setting && form.headline_setting.font_family}; color: ${form && form.headline_setting && form.headline_setting.font_color ? form.headline_setting.font_color : ''}; ${form && form.headline_setting && form.headline_setting.has_shadow ? 'text-shadow: ' + form.headline_setting.shadow_x +'px ' + form.headline_setting.shadow_y + 'px ' + form.headline_setting.shadow_blur + 'px ' + form.headline_setting.shadow_color : 'none'}`">{{ form.headline }}</h4>
                            <h1 v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form && form.subheadline_setting ? form.subheadline_setting.font_size / 2 : ''}px; line-height: ${form && form.subheadline_setting ? (parseInt(form.subheadline_setting.font_size) + 20) / 2 : ''}px; font-weight: ${form && form.subheadline_setting ? form.subheadline_setting.font_weight : ''}; font-family: ${form && form.subheadline_setting ? form.subheadline_setting.font_family : ''}; color: ${form && form.subheadline_setting && form.subheadline_setting.font_color  ? form.subheadline_setting.font_color : ''}; ${form && form.subheadline_setting && form.subheadline_setting.has_shadow ? 'text-shadow: ' + form.subheadline_setting.shadow_x +'px ' + form.subheadline_setting.shadow_y + 'px ' + form.subheadline_setting.shadow_blur + 'px ' + form.subheadline_setting.shadow_color : 'none'}`">{{ form.sub_headline }}</h1>
                        </div>
                    </div>
                </div>
                <div class="dashboard_content px-3">
                    <ul class="mobile_menu" v-if="tab == 'playbook' || tab == 'page' || tab == 'form'">
                        <!-- <li v-if="tab == 'form' && get_started" @click="get_started = 0;"><i class="fas fa-angle-left"></i>Back</li> -->
                        <!-- <li @click="openSlide('offer')" v-if="tab == 'playbook' || tab == 'page'">
                            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.82682 7.14098C4.55389 5.71402 5.71402 4.55388 7.14098 3.82682C8.09748 3.33947 9.09148 3.15918 10.0908 3.07753C11.0377 3.00018 12.1873 3.0002 13.5199 3.00025L25.1389 3.00013C26.1675 2.99917 27.0742 2.99833 27.9552 3.20979C28.7289 3.39556 29.4686 3.70199 30.1471 4.11777C30.9196 4.59109 31.5602 5.23292 32.2869 5.96092L51.8948 25.569C53.3337 27.0079 54.5213 28.1953 55.4096 29.2418C56.3322 30.3285 57.0795 31.4284 57.5052 32.7379C58.1649 34.7688 58.1649 36.9564 57.5052 38.9873C57.0795 40.2967 56.3322 41.3966 55.4096 42.4833C54.5213 43.5298 53.3337 44.7172 51.8948 46.156L46.1561 51.8948C44.7172 53.3337 43.5296 54.5213 42.4833 55.4096C41.3966 56.3322 40.2967 57.0797 38.987 57.5052C36.9564 58.1649 34.7688 58.1649 32.7379 57.5052C31.4285 57.0797 30.3286 56.3322 29.2418 55.4096C28.1953 54.5213 27.008 53.3337 25.569 51.8948L5.96092 32.2869C5.23292 31.5601 4.59107 30.9196 4.11778 30.1473C3.70197 29.4688 3.39557 28.7289 3.20979 27.9551C2.99832 27.0744 2.99917 26.1674 3.00013 25.1389L3.00026 13.5199C3.00021 12.1873 3.00017 11.0377 3.07754 10.0909C3.15919 9.09148 3.33947 8.09748 3.82682 7.14098ZM20.6954 15.6393C17.9032 15.6393 15.6396 17.9029 15.6396 20.6951C15.6396 23.4873 17.9032 25.7508 20.6954 25.7508C23.4876 25.7508 25.7511 23.4873 25.7511 20.6951C25.7511 17.9029 23.4876 15.6393 20.6954 15.6393Z" fill="#999999"/>
                            </svg>
                            Offer
                        </li> -->
                        <li @click="openSlide('instructor')" v-if="form.display_coach" :class="{'ml-auto' : tab == 'form' || tab == 'page'}">
                            <svg width="50" height="54" viewBox="0 0 50 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.0717 43.5574C7.3429 36.5812 15.646 32.0607 25.0001 32.0607C34.3541 32.0607 42.6572 36.5812 47.9283 43.5574C47.9521 43.5889 47.9761 43.6207 48.0001 43.6522C48.4533 44.2517 48.9393 44.8943 49.2866 45.5144C49.7068 46.2649 50.0186 47.1329 49.9991 48.1888C49.9836 49.0373 49.7266 49.8319 49.4143 50.4592C49.102 51.0865 48.6232 51.7705 47.9555 52.2947C47.0648 52.9936 46.0952 53.241 45.2338 53.3441C44.4783 53.4347 43.5827 53.4347 42.6824 53.4344C42.6417 53.4344 42.6011 53.4344 42.5605 53.4344H7.43943C7.39885 53.4344 7.35827 53.4344 7.31771 53.4344C6.41734 53.4347 5.5218 53.4347 4.76613 53.3441C3.9048 53.241 2.9352 52.9936 2.04467 52.2947C1.37687 51.7705 0.898042 51.0865 0.585771 50.4592C0.273501 49.8319 0.0163741 49.0373 0.000851407 48.1888C-0.0184651 47.1329 0.293084 46.2649 0.713453 45.5144C1.06072 44.8943 1.54671 44.2517 1.99991 43.6522C2.02396 43.6207 2.04787 43.5889 2.0717 43.5574Z" fill="#999999"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3047 14.6945C10.3047 6.57893 16.8836 0 24.9992 0C33.1146 0 39.6937 6.57893 39.6937 14.6945C39.6937 22.8101 33.1146 29.3889 24.9992 29.3889C16.8836 29.3889 10.3047 22.8101 10.3047 14.6945Z" fill="#999999"/>
                            </svg>
                            Coach
                        </li>
                        <li @click="openSlide('menu')" v-if="tab == 'playbook'" class="ml-auto">
                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M47.8032 2.19668C50.7321 5.12558 50.7321 9.8743 47.8032 12.8032C44.8742 15.7321 40.1257 15.7321 37.1967 12.8032C34.2678 9.8743 34.2678 5.12558 37.1967 2.19668C40.1257 -0.732226 44.8742 -0.732226 47.8032 2.19668Z" fill="#999999"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M47.4997 22.0136C47.4997 20.9916 47.4997 20.4806 47.3337 20.1916C47.1672 19.9012 46.9979 19.7579 46.6839 19.6416C46.3714 19.5258 45.7739 19.6261 44.5792 19.8266C40.728 20.4728 36.6325 19.3103 33.661 16.3387C30.6893 13.3671 29.5268 9.27152 30.173 5.42042C30.3735 4.22555 30.4738 3.62813 30.358 3.31563C30.2418 3.00176 30.0985 2.83249 29.808 2.66586C29.519 2.49994 29.008 2.49994 27.986 2.49994H14.3966C12.3843 2.49991 10.7233 2.49989 9.37038 2.61041C7.96514 2.72524 6.6734 2.97164 5.46006 3.58988C3.57845 4.5486 2.04866 6.07839 1.08994 7.96C0.471698 9.17332 0.225299 10.4651 0.1105 11.8703C-4.93877e-05 13.2233 -2.42479e-05 14.8841 7.51906e-07 16.8965V35.603C-2.42479e-05 37.6154 -4.93877e-05 39.2762 0.1105 40.6292C0.225299 42.0344 0.471698 43.3261 1.08994 44.5396C2.04866 46.4211 3.57845 47.9509 5.46006 48.9096C6.6734 49.5279 7.96514 49.7743 9.37038 49.8891C10.7233 49.9996 12.3842 49.9996 14.3966 49.9996H33.103C35.1155 49.9996 36.7762 49.9996 38.1292 49.8891C39.5345 49.7743 40.8262 49.5279 42.0397 48.9096C43.9212 47.9509 45.4509 46.4211 46.4097 44.5396C47.0279 43.3261 47.2744 42.0344 47.3892 40.6292C47.4997 39.2762 47.4997 37.6154 47.4997 35.603V22.0136ZM9.99993 27.4998C9.99993 26.119 11.1192 24.9998 12.4999 24.9998H32.4998C33.8805 24.9998 34.9997 26.119 34.9997 27.4998C34.9997 28.8805 33.8805 29.9997 32.4998 29.9997H12.4999C11.1192 29.9997 9.99993 28.8805 9.99993 27.4998ZM9.99993 37.4997C9.99993 36.1189 11.1192 34.9997 12.4999 34.9997H27.4998C28.8805 34.9997 29.9998 36.1189 29.9998 37.4997C29.9998 38.8804 28.8805 39.9997 27.4998 39.9997H12.4999C11.1192 39.9997 9.99993 38.8804 9.99993 37.4997Z" fill="#999999"/>
                            </svg>
                            Menu
                        </li>
                    </ul>
                    <div class="title_box w-100" v-if="tab == 'playbook'">
                        <h3 class="m-0">Playbook Contents</h3>
                    </div>
                    <div class="left_side" v-if="tab == 'playbook'">
                        <div class="section_item border-item progress_sec" v-if="form.display_progressbar">
                            <h3>Steps Completed</h3>
                            <div class="status mb-1 mt-2"><span :style="`background-color: ${form.progressbar_color ? form.progressbar_color : '#2f7eed'}; width:100%;`"></span></div>
                        </div>
                        <div class="step_card">
                            <div class="thumb">
                                <img src="@/assets/images/thumb/default-step-cover.svg" alt="">
                            </div>
                            <div class="step_info pointer">
                                <h5>Getting Started</h5>
                                <p>This is where your getting started content goes</p>
                            </div>
                        </div>
                        <div class="step_card">
                            <div class="thumb">
                                <img src="@/assets/images/thumb/default-step-cover.svg" alt="">
                            </div>
                            <div class="step_info pointer">
                                <h5>Week 1</h5>
                                <p>This is where your week 1 content goes...</p>
                            </div>
                        </div>
                        <div class="step_card">
                            <div class="thumb">
                                <img src="@/assets/images/thumb/default-step-cover.svg" alt="">
                            </div>
                            <div class="step_info pointer">
                                <h5>Week 2</h5>
                                <p>This is where your week 2 content goes...</p>
                            </div>
                        </div>
                        <div class="step_card">
                            <div class="thumb">
                                <img src="@/assets/images/thumb/default-step-cover.svg" alt="">
                            </div>
                            <div class="step_info pointer">
                                <h5>Keep Going</h5>
                                <p>Add as many training steps as you desire!</p>
                            </div>
                        </div>
                        <div class="section_item faqs">
                            <h3>Playbook FAQ's</h3>
                            <ul class="faq_list">
                                <li class="small-preview" @click="faqAcc($event)">
                                    <h5 class="faq-acc-header">How do I get started? <i class="fas fa-angle-down"></i></h5>
                                    <p>This is where you can post your playbook FAQ questions...</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="left_side" v-if="tab == 'page'" :class="{'narrow' : !form.display_coach}">
                        <div class="content_details">
                            <div class="section_item media_title bord-t">
                                <h3 class="m-0">
                                    Media Headline
                                </h3>
                            </div>
                            <div class="section_item video_wpr p-0 b-round">
                                <img src="@/assets/images/thumb/video-placeholder.svg" />
                            </div>
                            <div class="section_item py-3 mt-5">
                                <h3 class="m-0">
                                    Content Headline
                                </h3>
                            </div>
                            <div class="section_item p-0 mb-5">
                                <p class="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div class="button_list mb-5" v-if="form.cta_button.display">
                            <button class="justify-content-center pointer" :style="`color : ${ form.cta_button && form.cta_button.btn_text_color ? form.cta_button.btn_text_color : '' }; background-color: ${ form.cta_button && form.cta_button.btn_color ? form.cta_button.btn_color : '' };`" type="button">{{ form.cta_button && form.cta_button.text ? form.cta_button.text : '' }}</button>
                        </div>
                    </div>
                    <div class="left_side" v-if="tab == 'form'" :class="{'narrow' : tab == 'form' && !form.display_progressbar && !form.display_coach || tab == 'form' && !form.display_coach}">
                        <div class="section_item border-item progress_sec progress_form" v-if="form.display_progressbar && !form.display_coach">
                            <h3>Questions Completed</h3>
                            <div class="status mb-1 mt-2"><span :style="`background-color: ${form.progressbar_color ? form.progressbar_color : '#2f7eed'}; width:100%;`"></span></div>
                        </div>
                        <div class="progress_sec progress_form mobile" v-if="form.display_progressbar">
                            <h3>Questions Completed</h3>
                            <div class="status mb-1 mt-2"><span :style="`background-color: ${form.progressbar_color ? form.progressbar_color : '#2f7eed'}; width:100%;`"></span></div>
                        </div>
                        <!-- <template v-if="!get_started">
                            <div class="section_item video_wpr p-0">
                                <img src="@/assets/images/thumb/video-placeholder.svg" />
                            </div>
                            <div class="get_start">
                                <button class="primary_btn" @click="get_started = 1;" :style="`color : ${ form.cta_button && form.cta_button.btn_text_color ? form.cta_button.btn_text_color : '' }; background-color: ${ form.cta_button && form.cta_button.btn_color ? form.cta_button.btn_color : '' };`" type="button">{{ form.cta_button && form.cta_button.text ? form.cta_button.text : '' }}</button>
                            </div>
                        </template> -->
                        <template v-if="get_started">
                            <h3 class="title">Please answer the following:</h3>
                            <div class="edit_section">
                                <div class="quest_wpr">
                                    <h5><label class="q_index">1</label>Do you love your new program?</h5>
                                    <ul class="question-options">
                                        <li>
                                            <span class="radio_box"></span>
                                            <p>Absolutely!</p>
                                        </li>
                                        <li>
                                            <span class="radio_box"></span>
                                            <p>Sort of..</p>
                                        </li>
                                        <li>
                                            <span class="radio_box"></span>
                                            <p>Not so much</p>
                                        </li>
                                    </ul>
                                </div>
                                <div class="quest_wpr">
                                    <h5><label class="q_index">2</label>How can we improve your experience?</h5>
                                    <div class="setting_wpr mt-2">
                                        <div class="form_grp py-2">
                                            <div class="group_item">
                                                <div class="field_wpr mb-3">
                                                    <textarea placeholder="Content goes here.." disabled></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="submit_btn">
                                <button class="primary_btn" type="button" style="background: #d4d4d4; color: #2c3e50;">SUBMIT</button>
                                <div class="info text-center">Your submission is encrypted, secure, and confidential</div>
                            </div>
                        </template>
                    </div>
                    <div class="right_side no_space" v-if="tab == 'playbook' || tab == 'page' || tab == 'form' && form.display_coach">
                        <div class="section_item border-item progress_sec" v-if="tab == 'playbook' && form.display_progressbar">
                            <h3>Steps Completed</h3>
                            <div class="status mb-1 mt-2"><span :style="`background-color: ${form.progressbar_color ? form.progressbar_color : '#2f7eed'}; width:100%;`"></span></div>
                        </div>
                        <div class="section_item border-item progress_sec progress_form" v-if="tab == 'form' && form.display_progressbar">
                            <h3>Questions Completed</h3>
                            <div class="status mb-1 mt-2"><span :style="`background-color: ${form.progressbar_color ? form.progressbar_color : '#2f7eed'}; width:100%;`"></span></div>
                        </div>
                        <!-- <div class="section_item product_card mb-5" v-if="tab == 'playbook' || tab == 'page'">
                            <h3>Offer Headline</h3>
                            <img src="@/assets/images/thumb/default-step-cover.svg" alt="product-image">
                        </div> -->
                        <div class="section_item coach_card" v-if="form.display_coach">
                            <h3>{{ form.coach_headline ? form.coach_headline : 'Coach Headline' }}</h3>
                            <div class="user_box">
                                <img :src="form.coach_image && form.coach_image != 'null' ? form.coach_image : require('@/assets/images/customer.svg')" alt="">
                                <div class="user_info">
                                    <h3>{{ form.coach_name ? form.coach_name : 'Joe Trainer' }}</h3>
                                    <h5>{{ form.coach_title ? form.coach_title : 'PHD, CSCS' }}</h5>
                                </div>
                            </div>
                            <p v-html="form.coach_bio"></p>
                        </div>
                        <div class="section_item border-item act_area" v-if="tab == 'playbook'">
                            <h3>Additional Actions</h3>
                            <ul class="button_list mb-3">
                                <li v-if="form.recipient_ask.display == 1">
                                    <button type="button" :style="`background-color: ${ form.recipient_ask.button_color }; color: ${ form.recipient_ask.button_txt_color };`">
                                        <i class="fas fa-plus"></i>{{ form.recipient_ask.button_text }}
                                    </button>
                                </li>
                                <li v-if="form.recipient_understand.display == 1">
                                    <button type="button" :style="`background-color: ${ form.recipient_understand.button_color }; color: ${ form.recipient_understand.button_txt_color };`">
                                        <i class="fas fa-plus"></i>{{ form.recipient_understand.button_text }}
                                    </button>
                                </li>
                                <li v-if="form.recipient_complete.display == 1">
                                    <button type="button" :style="`background-color: ${ form.recipient_complete.button_color }; color: ${ form.recipient_complete.button_txt_color };`">
                                        <i class="fas fa-plus"></i>{{ form.recipient_complete.button_text }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <footer class="dashboard_footer" v-show="form.display_footer" :style="`background: ${form.footer_bgcolor};`">
                    <ul>
                        <li class="pointer" v-if="form.has_footer_facebook"><a :href="form.footer_facebook" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-facebook-f"></i></a></li>
                        <li class="pointer" v-if="form.has_footer_twitter"><a :href="form.footer_twitter" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-twitter"></i></a></li>
                        <li class="pointer" v-if="form.has_footer_instagram"><a :href="form.footer_instagram" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                    <h4 :style="`color: ${form.footer_textcolor};`">© {{ form.footer_company }}</h4>
                    <p>
                        <a href="javascript:void(0);" :style="`color: ${form.footer_textcolor};`">Terms</a>
                        <a href="javascript:void(0);" :style="`color: ${form.footer_textcolor};`">Privacy</a>
                        <span v-html="form.white_label"></span>
                    </p>
                </footer>
            </div>
        </template>
        <template v-if="form && form.public_settings && (tab == 'progress-tracking' || tab == 'habit-tracking')">
            <div class="content_area">
                <preview-content :preview-tab="tab" :preview-page="previewPage" :content-form="form" :device="device" />
            </div>
        </template>
        <!-- Instructor & Step listing -->
        <div class="slide_box_container" :style="`pointer-events:${instructorDetails || steplistDetails || offerDetails ? 'all' : 'none'}`" v-if="tab == 'playbook' || tab == 'page' || tab == 'form'">
            <div class="slide_box" :class="{'active' : steplistDetails}" v-if="tab == 'playbook'">
                <button class="close_btn" @click="steplistDetails = false;"><i class="fas fa-times"></i></button>
                <div class="box_container">
                    <div class="step_listing border-0 p-0">
                        <div class="progress_sec" v-if="tab == 'playbook' && form.display_progressbar">
                            <h3>Steps Completed</h3>
                            <div class="status mb-1 mt-2"><span :style="`background-color: ${form.progressbar_color ? form.progressbar_color : '#2f7eed'}; width:100%;`"></span></div>
                        </div>
                        <h3 class="mb-4">Course Content</h3>
                        <ul>
                            <li class="step_card">
                                <div class="thumb">
                                    <img src="@/assets/images/thumb/default-step-cover.svg" alt="">
                                </div>
                                <div class="step_info pointer">
                                    <h5>Getting Started</h5>
                                    <p>This is where your getting started content goes</p>
                                </div>
                            </li>
                            <li class="step_card">
                                <div class="thumb">
                                    <img src="@/assets/images/thumb/default-step-cover.svg" alt="">
                                </div>
                                <div class="step_info pointer">
                                    <h5>Week 1</h5>
                                    <p>This is where your week 1 content goes...</p>
                                </div>
                            </li>
                            <li class="step_card">
                                <div class="thumb">
                                    <img src="@/assets/images/thumb/default-step-cover.svg" alt="">
                                </div>
                                <div class="step_info pointer">
                                    <h5>Week 2</h5>
                                    <p>This is where your week 2 content goes...</p>
                                </div>
                            </li>
                            <li class="step_card">
                                <div class="thumb">
                                    <img src="@/assets/images/thumb/default-step-cover.svg" alt="">
                                </div>
                                <div class="step_info pointer">
                                    <h5>Keep Going</h5>
                                    <p>Add as many training steps as you desire!</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="slide_box" :class="{'active' : instructorDetails}" v-if="form.display_coach">
                <button class="close_btn" @click="instructorDetails = false;"><i class="fas fa-times"></i></button>
                <div class="box_container">
                    <div class="instructor" v-if="form.display_coach">
                        <h3>{{ form.coach_headline ? form.coach_headline : 'Coach Headline' }}</h3>
                        <div class="user_box">
                            <img :src="form.coach_image && form.coach_image != 'null' ? form.coach_image : require('@/assets/images/customer.svg')" alt="">
                            <div class="user_info">
                                <h3>{{ form.coach_name ? form.coach_name : 'Joe Trainer' }}</h3>
                                <h5>{{ form.coach_title ? form.coach_title : 'PHD, CSCS' }}</h5>
                            </div>
                        </div>
                        <p v-html="form.coach_bio"></p>
                    </div>
                </div>
            </div>
            <div class="slide_box" :class="{'active' : offerDetails}">
                <button class="close_btn" @click="offerDetails = false;"><i class="fas fa-times"></i></button>
                <div class="box_container">
                    <div class="section_item product_card" v-if="tab == 'playbook'">
                        <h3>Offer Headline</h3>
                        <img src="@/assets/images/thumb/offer-placeholder.svg" alt="product-image">
                    </div>
                    <div class="section_item product_card" v-if="tab == 'page'">
                        <h3>Offer Headline</h3>
                        <img src="@/assets/images/thumb/offer-placeholder.svg" alt="product-image">
                    </div>
                </div>
            </div>
        </div>
    </full-preview>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'
    import { Form } from 'vee-validate'

    const DefaultContentForm = defineAsyncComponent(() => import('@/pages/admin-setting/components/content-setting/DefaultContentForm'))
    const FullPreview = defineAsyncComponent(() => import('@/components/FullPreview'))
    const PreviewContent = defineAsyncComponent(() => import('@/pages/admin-setting/components/content-setting/PreviewContent'))

    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Default Content',

        data () {
            return {
                playbookForm: {},
                pageForm: {},
                formForm: {},
                tab: 'playbook',
                loader: false,
                contentLoader: false,
                preview: true,
                fullPreview: false,
                accountDrop: false,
                form: '',
                device: 'desk',
                progressTrackingForm: {
                    public_settings: {
                        display_explainer_video: 0,
                        explainer_video_type: 0,
                        explainer_video: "",
                        explainer_embed: "",
                        display_company_branding: 1,
                        company_branding: "",
                        display_cover: 1,
                        cover_type: 2,
                        headline: 'Time To Submit Your Progress',
                        subheadline: '',
                        display_logo:1,
                        logo: '',
                        display_author:1,
                        author:"",
                        display_header:1,
                        display_footer:1,
                        header_bgcolor: '#FFF',
                        header_textcolor: '#000',
                        submit_btn_text: 'Submit Progress',
                        element_color: '#2c3e50',
                        element_text_color: '#2C3E50',
                        submit_btn_textcolor: '#fff',
                        submit_btn_bgcolor: '#2c3e50',
                        footer_bgcolor: '#D4D4D4',
                        footer_textcolor: '#2C3E50',
                        cover_color: '#1976d2',
                        cover_textcolor: '#2C3E50',
                        cover: '',
                        small_cover: '',
                        footer_has_facebook: 1,
                        footer_has_twitter: 1,
                        footer_has_instagram: 1,
                        thankyou_bgcolor: '#edebeb',
                        thankyou_textcolor: '#2c3e50',
                        seo_title: '{{company_name}} Client Tracking',
                        seo_keyword: 'Client tracking',
                        seo_desc: `Whatever can be measured can be improved and what you focus on becomes reality. Let's track your client tracking!`,
                        seo_index: 0,
                        seo_share_title: '{{company_name}} Client Tracking',
                        seo_share_desc: `Whatever can be measured can be improved and what you focus on becomes reality. Let's track your client tracking!`,
                        seo_thumb: '',
                        seo_fav: '',
                    },
                    headline_setting: {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 50,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.25)',
                    },
                    subheadline_setting: {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 25,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.68)',
                    },
                },
                habitTrackingForm: {
                    public_settings: {
                        display_explainer_video: 0,
                        explainer_video_type: 0,
                        explainer_video: '',
                        explainer_embed: '',
                        display_company_branding: 1,
                        company_branding: '',
                        display_cover: 0,
                        cover_type: 2,
                        headline: 'Time To Submit Your Habits',
                        subheadline: '',
                        display_logo: 0,
                        logo: '',
                        display_author: 0,
                        author:'',
                        display_header: 1,
                        display_footer: 0,
                        header_bgcolor: '#FFF',
                        header_textcolor: '#000',
                        submit_btn_text:  'Submit',
                        element_color:  '#D4D4D4',
                        element_text_color: '#2C3E50',
                        submit_btn_textcolor: '#fff',
                        submit_btn_bgcolor: '#2c3e50',
                        footer_bgcolor: '#D4D4D4',
                        footer_textcolor: '#2C3E50',
                        cover_color:  '#1976d2',
                        cover_textcolor:  '#2C3E50',
                        cover: '',
                        small_cover: '',
                        seo_title: 'Join our Habit Challenge',
                        seo_keyword: 'Habits',
                        seo_desc: 'Whatever can be measured can be improved.',
                        seo_index: 0,
                        seo_share_title: 'Join our habit challenge',
                        seo_share_desc: 'Whatever can be measured can be improved',
                        seo_thumb: '',
                        seo_fav: '',
                        footer_has_facebook: 1,
                        footer_has_twitter: 1,
                        footer_has_instagram: 1,
                        thankyou_bgcolor: '#FFFFFF',
                        thankyou_textcolor: '#2C3E50',
                        overlay_color: '#2c3e50',
                        overlay_opacity: 50,
                    },
                    headline_setting: {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 50,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.25)',
                    },
                    subheadline_setting: {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 25,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.68)',
                    },
                },
                previewPage: 'main',
                offerDetails: false,
                instructorDetails: false,
                steplistDetails: false,
                get_started: 1,
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Form,
            DefaultContentForm,
            FullPreview,
            PreviewContent,
        },

        watch: {
            savedStyle (contents) {
                const vm = this;

                if (Object.values(contents).length) {
                    contents.forEach((content) => {
                        if (content.content_type == 1) {
                            vm.playbookForm = content;
                        } else if (content.content_type == 2) {
                            vm.pageForm = content;
                        } else if (content.content_type == 3) {
                            vm.formForm = content;
                        } else if (content.content_type == 4) {
                            vm.progressTrackingForm = content;
                        } else if (content.content_type == 5) {
                            vm.habitTrackingForm = content;
                        }
                    });

                }
            },

            modelValue (value) {
                const vm = this;

                if (value) {
                    document.body.classList.add('modal-open');

                    vm.contentLoader = true;
                    setTimeout(function () {
                        vm.getDefaultContent().then(() => { vm.contentLoader = false; });
                    }, 1000);

                    if (vm.tagsGroup.length == 0) {
                        vm.getTags();
                    }

                    vm.tab = 'playbook';
                } else {
                    document.body.classList.remove('modal-open');
                    vm.tab = 'playbook';
                }
            },

            fullPreview(val){
                const vm = this;

                vm.get_started = 1;
            }
        },

        computed: mapState({
            user: state => state.authModule.user,
            savedStyle: state => state.commonModule.savedStyle,
            tagsGroup: state => state.tagModule.tagsGroup,
            tagLoader: state => state.tagModule.tagLoader,
        }),

        mounted () {
            window.addEventListener("resize", this.myEventHandler);
            this.myEventHandler();
        },
        unmounted() {
            window.removeEventListener("resize", this.myEventHandler);
        },

        methods: {
            ...mapActions({
                getDefaultContent: 'commonModule/getDefaultContent',
                saveDefaultContent: 'commonModule/saveDefaultContent',
                getTags: 'tagModule/getTags',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handlePlaybookContentSubmit (params, { setFieldError }) {
                const vm = this;
                let component = '';

                if (vm.tab == 'playbook') {
                    component = vm.$refs['playbook-form'];
                } else if (vm.tab == 'page') {
                    component = vm.$refs['page-form'];
                } else if (vm.tab == 'form') {
                    component = vm.$refs['form-form'];
                } else if (vm.tab == 'progress-tracking') {
                    component = vm.$refs['progress-tracking-form'];
                } else if (vm.tab == 'habit-tracking') {
                    component = vm.$refs['habit-tracking-form'];
                }

                if (component) {
                    const params = component.setFormData();
                    vm.loader    = true;

                    vm.saveDefaultContent(params).then((result) => {
                        vm.contentLoader = true;
                        vm.getDefaultContent().then(() => { vm.contentLoader = false; });
                        vm.loader = false;
                    });
                } else {
                    Toastr.error('Something went wrong, please try again!');
                }
            },

            getData (data) {
                const vm = this;

                vm.form  = data;
            },

            setDefaultAvatar (event) {
                event.target.src = require('@/assets/images/table-user.png');
            },

            toggleSectionbar(){
                const ls_bar = document.querySelector('.global_setting');
                if(ls_bar.classList.contains('show_m_sidebar')){
                    ls_bar.classList.remove('show_m_sidebar');
                }else{
                    ls_bar.classList.add('show_m_sidebar');
                }
            },
            myEventHandler(){
                let screenWidth = window.innerWidth;
                if(screenWidth < 992){
                    this.preview = false;
                }else{
                    this.preview = true;
                }
            },

            openSlide(type){
                const vm = this;
                if(type == 'offer'){
                    vm.steplistDetails = false;
                    vm.instructorDetails = false;
                    vm.offerDetails = true;
                } else if(type == 'instructor'){
                    vm.offerDetails = false;
                    vm.steplistDetails = false;
                    vm.instructorDetails = true;
                } else if(type == 'menu'){
                    vm.instructorDetails = false;
                    vm.offerDetails = false;
                    vm.steplistDetails = true;
                }
            },

            faqAcc (e) {
                let el = e.currentTarget;
                let allEl = document.querySelectorAll('.faq_list li');

                if (el.classList.contains('show')) {
                    el.classList.remove('show');
                } else {
                    for (let i = 0; i < allEl.length; i++) {
                        allEl[i].classList.remove('show');
                    }

                    el.classList.add('show');
                }
            },
        },
    };
</script>

<style scoped>
    .setting-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }
    .preview_content {
        position: relative;
        background: #fff;
        transition: all 0.3s ease-in-out;
        z-index: 4;
        margin-right: -40%;
    }
    .preview_content.show {
        margin-right: 0;
    }
    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 120px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
        z-index: 4;
    }
    .preview_content .close_btn{
        position: absolute;
        left: -35px;
        top: 5px;
    }
    :deep(.group_item) {
        max-width: 100%;
    }
    :deep(.color_container .tab_content){
        height: auto;
    }
    .area_body .dashboard{
        background: #f5f5f5;
    }
    .area_body .dashboard_content {
        max-width: 1170px;
        padding: 60px 0;
        justify-content: center;
    }
    .dashboard_content .title_box{
        padding: 10px 20px 25px;
        border-radius: 8px;
    }
    .area_body .dashboard_content .left_side{
        flex: 1 1 60%;
        padding: 0 20px;
    }
    .area_body .dashboard_content .left_side.narrow{
        flex: 0 1 700px;
    }
    .area_body .dashboard_content .right_side{
        flex: 1 1 28%;
        padding: 0 20px;
    }
    .area_body.tab .dashboard_content , .area_body.cell .dashboard_content {
        padding: 40px 0;
    }
    .area_body.tab .dashboard_content .section_item h3{
        font-size: 18px;
        line-height: 25px;
    }
    
    .area_body.tab .dashboard_content .title_box{
        padding: 10px 20px 20px;
    }
    .area_body.cell .dashboard_content .title_box{
        padding: 10px 0 15px;
    }

    .area_body.tab .dashboard_content .right_side,
    .area_body.cell .dashboard_content .right_side {
        flex: 0 0 100%;
        margin-top: 25px;
        padding-top: 40px;
        position: relative;
    }
    .cell .dashboard_content .left_side,
    .cell .dashboard_content .right_side {
        width: 100%;
        position: relative;
    }
    .area_body.tab .dashboard_content .right_side:before {
        content: "";
        position: absolute;
        top: 0;
        left: 20px;
        right: 20px;
        border-top: 1px solid #e9e9e9;
    }
    .area_body.tab .dashboard_content .right_side .progress_sec,
    .area_body.tab .dashboard_content .right_side .coach_card,
    .area_body.tab .dashboard_content .right_side .product_card,
    .area_body.cell .dashboard_content .right_side .progress_sec,
    .area_body.cell .dashboard_content .right_side .coach_card,
    .area_body.cell .dashboard_content .right_side .product_card {
        display: none;
    }
    .area_body.tab .dashboard.page .dashboard_content .right_side,
    .area_body.cell .dashboard.page .dashboard_content .right_side,
    .area_body.tab .dashboard.form .dashboard_content .right_side,
    .area_body.cell .dashboard.form .dashboard_content .right_side{
        display: none;
    }

    .area_body.tab .dashboard_content .act_area,
    .area_body.cell .dashboard_content .act_area{
        padding: 0;
        background: transparent;
    }
    .area_body.tab .dashboard_content .act_area h3,
    .area_body.cell .dashboard_content .act_area h3{
        font-weight: 500;
    }
    .area_body.tab .button_list li button,
    .area_body.cell .button_list li button{
        background: transparent !important;
        color: #121525 !important;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        padding: 0;
        justify-content: flex-start;
        text-align: left;
    }
    .area_body.tab .step_card .thumb{
        flex: 0 0 170px;
    }
    .area_body.cell .step_card .thumb{
        flex: 0 0 140px;
    }
    .area_body.tab .step_card .step_info {
        padding: 8px 20px;
        height: 117px;
    }
    .area_body.cell .step_card .step_info {
        padding: 8px 20px;
        height: auto;
    }
    .area_body.tab .step_card .step_info p{
        font-size: 13px;
        line-height: 18px;
    }
    .area_body.cell .step_card .step_info p{
        font-size: 11px;
        line-height: 15px;
    }

    .preview_content .cell{
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0,0,0,0.2);
        position: relative;
        margin: 30px auto;
    }
    .preview_content .cell:after, .preview_content .cell:before{
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }
    .preview_content .cell:before{
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }
    .preview_content .cell:after{
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }
    .preview_content .cell .content_area{
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #f5f5f5;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .preview_content .cell .content_area .dashboard_content{
        flex-direction: column;
        flex-grow: 1;
        flex-wrap: inherit;
        margin-bottom: 50px;
    }
    .preview_content .dashboard_footer {
        width: 100%;
        text-align: center;
        padding: 20px 0;
    }
    .preview_content .dashboard_footer ul li{
        padding: 2px;
    }
    .preview_content .dashboard_footer ul li a {
        width: 20px;
        height: 20px;
        border: 1px solid #2f7eed;
        font-size: 10px;
        color: #2f7eed;
        text-decoration: none;
    }
    .preview_content .dashboard_footer h4 {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0 5px;
    }
    .preview_content .dashboard_footer p {
        font-size: 9px;
        line-height: 11px;
    }
    .preview_content .dashboard_footer p a:after {
        top: 5px;
        width: 2px;
        height: 2px;
    }
    .client_card{
        width: 100%;
        line-height: 0;
    }
    .client_card .card_body{
        text-align: center;
    }
    .area_body.tab .client_card .card_body.no_sm_cover,
    .area_body.cell .client_card .card_body.no_sm_cover{
        display: none;
    }
    .cell .client_card .card_header {
        padding: 15px 20px;
        border-radius: 0;
    }

    .cell .client_card .card_header .user_box>img {
        width: 20px;
        height: 20px;
    }

    .cell .client_card .card_header .user_box h5 {
        font-size: 11px;
        line-height: 13px;
    }

    .cell .client_card .card_header .user_info {
        margin-left: 10px;
    }

    .cell .client_card .card_header .user_info span {
        font-size: 10px;
        line-height: 12px;
    }
    .area_body.cell .dashboard .dashboard_content .left_side{
        width: 100%;
        padding: 0;
    }
    .cell .dashboard_content .right_side {
        width: 100%;
        padding: 15px;
        margin-top: 15px;
        position: relative;
    }
    .cell .dashboard_content .right_side::before {
        content: '';
        position: absolute;
        top: 0;
        left: 20px;
        right: 20px;
        border-top: 1px solid #e9e9e9;
    }
    .cell .dashboard_content .right_side.no_space::before {
        left: 0;
        right: 0;
    }
    .border-item {
        padding: 20px 30px;
        border-radius: 8px;
        background: #fff;
    }
    .cell .border-item {
        padding: 15px;
        border-radius: 8px;
        background: #fff;
    }
    .button_list li {
        margin-top: 20px;
    }
    .tab .button_list li {
        margin-top: 20px;
    }
    .cell .dashboard_content .action_sec h3{
        font-weight: 500;
    }
    .cell .button_list li {
        margin-top: 20px;
    }
    .button_list li button, .button_list li a {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #fff;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        cursor: pointer;
        text-decoration: none;
    }
    .button_list li button i{
        font-size: 9px;
        padding-right: 10px;
    }
    .area_body.desk .button_list li button i{
        display: none;
    }
    .cell .button_list li button, .cell .button_list li a  {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #121525 !important;
        background: transparent !important;
        padding: 0;
        margin-top: 20px;
    }
    .cell .button_list li button{
        justify-content: flex-start;
    }
    .progress_sec{
        margin-bottom: 35px;
    }
    .progress_sec h3{
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 15px;
    }
    .desk .left_side .progress_sec.mobile{
        display: none;
    }
    .tab .left_side .progress_sec.mobile{
        display: block;
        margin-top: 15px;
        margin-bottom: 20px;
        padding: 23px 30px;
    }
    .tab .left_side .progress_sec,
    .cell .left_side .progress_sec{
        display: none;
    }
    .tab .dashboard.form .left_side .progress_sec{
        padding: 0;
        margin-top: 15px;
        margin-bottom: 45px;
    }
    .cell .dashboard.form .left_side .progress_sec{
        padding: 0;
        margin-top: 10px;
        margin-bottom: 45px;
    }
    .cell .left_side .progress_sec.mobile{
        display: block;
        margin-bottom: 20px;
        padding: 20px;
    }
    .cell .left_side .progress_sec.progress_form{
        padding: 0;
        margin-bottom: 40px;
    }
    .cell .dashboard .dashboard_content .left_side .progress_sec h3 {
        font-size: 17px;
        line-height: 22px;
    }
    .status {
        height: 5px;
        border-radius: 3px;
        background: #ddd;
        position: relative;
        max-width: 100%;
    }

    .status span {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 3px;
        background: #2f7fed;
    }
    .coach_card {
        border-radius: 8px;
        padding: 20px 30px;
        background: #fff;
        margin-bottom: 45px;
    }
    .cell .coach_card {
        padding: 10px 15px;
        margin-bottom: 35px;
    }

    .coach_card .user_box {
        display: flex;
        align-items: center;
        margin: 25px 0;
    }
    .cell .coach_card .user_box{
        margin: 15px 0;
    }

    .coach_card .user_box img {
        width: 60px;
        height: 60px;
        margin-right: 15px;
    }
    .cell .coach_card .user_box img {
        width: 40px;
        height: 40px;
    }

    .coach_card .user_box .user_info h3 {
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        color: #121525;
        margin: 0;
    }
    .cell .coach_card .user_box .user_info h3 {
        font-size: 14px;
        line-height: 17px;
    }


    .coach_card .user_box .user_info h5 {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        margin: 5px 0 0 0;
    }
    .cell .coach_card .user_box .user_info h5 {
        font-size: 11px;
        line-height: 13px;
    }

    :deep(.coach_card p p) {
        font-size: 15px;
        line-height: 27px;
        min-height: 27px;
        font-weight: 300;
        color: #0e101a;
        display: block;
        font-family: 'Inter', sans-serif;
    }
    :deep(.coach_card p:empty) {
        min-height: 27px;
    }
    .tab .dashboard_content .section_item.faqs, 
    .cell .dashboard_content .section_item.faqs{
        padding-top: 35px;
        padding-bottom: 5px;
    }
    .faq_list li {
        border: 1px solid #eaeaea;
        padding: 15px;
        border-radius: 6px;
        margin: 15px 0;
        background: #fff;
        overflow: hidden;
    }

    .faq_list li h5 {
        font-size: 16px;
        line-height: 23px;
        color: #121525;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .tab .faq_list li h5 {
        font-size: 14px;
        line-height: 19px;
    }
    .cell .faq_list li h5 {
        font-size: 14px;
        line-height: 19px;
    }

    .faq_list li h5 i {
        transform: rotate(0deg);
        transition: all 0.3s ease-in-out;
    }
    .faq-acc-header {
        padding: 15px 20px;
        margin: -15px -15px 0px -15px;
    }

    ul.faq_list li.small-preview {
        padding-bottom: 0;
    }

    ul.faq_list li.small-preview .faq-acc-header {
        background: #d4d4d4;
    }
    ul.faq_list li p{
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 15px 0;
        display: none;
    }
    .tab ul.faq_list li p, .cell ul.faq_list li p{
        font-size: 12px;
        line-height: 18px;
        padding: 15px 0;
    }
    ul.faq_list li.show p {
        display: block;
    }
    .step_card {
        display: flex;
        padding: 5px 0 5px 5px;
        /* border: 1px solid #e9e9e9; */
        border-radius: 8px;
        margin-bottom: 20px;
        overflow: hidden;
        background: #fff;
        position: relative;
        /* min-height: 154px; */
    }
    .cell .step_card{
        border-radius: 5px;
    }

    .step_card .thumb {
        flex: 0 0 200px;
        border-radius: 8px 0 0 8px;
        position: relative;
    }
    .cell .step_card .thumb {
        flex: 0 0 140px;
    }

    .step_card .thumb>img {
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 5px 0 0 5px;
    }

    .step_card .step_info {
        padding: 15px 20px;
        text-decoration: none;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 117px;
    }
    .cell .step_card .step_info {
        height: 91px;
    }
    .cell .step_card .step_info{
        padding: 8px 20px;
    }

    .step_card .step_info h5 {
        font-size: 16px;
        line-height: 21px;
        font-weight: 500;
        color: #121525;
        align-items: center;
        max-height: 42px;
        margin-bottom: 9px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .cell .step_card .step_info h5{
        margin-bottom: 9px;
        font-size: 13px;
        line-height: 18px;
        max-height: 36px;
    }
    .tab .step_card .step_info p{
        height: 28px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;;
    }
    .cell .step_card .step_info p{
        font-size: 11px;
        line-height: 15px;
        max-height: 36px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;;
    }

    .step_card .step_info h3 .step_status {
        font-size: 14px;
        line-height: 18px;
        margin-right: 10px;
    }

    .step_card .step_info h3 a {
        text-decoration: none;
        color: inherit;
    }

    .edit {
        font-size: 18px;
        margin-left: 15px;
    }

    .step_card .step_info p {
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        height: 36px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .step_card .thumb .replace_img {
        position: absolute;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 15px;
        top: 12px;
    }

    .step_card .thumb .replace_img img {
        max-width: 12px;
        height: auto;
    }
    .dashboard_content h3 {
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 15px;
    }
    .cell .dashboard_content h3,
    .cell .dashboard .dashboard_content h3{
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 15px;
        font-weight: 400;
    }
    .tab .dashboard .dashboard_content h3.title,
    .cell .dashboard .dashboard_content h3.title{
        margin-top: 10px;
    }
    .content_details{
        background: #fff;
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 40px;
    }
    .media_title{
        padding: 20px 0;
    }
    .cell .media_title{
        padding: 0 0 15px 0;
    }
    .cell .media_title h3{
        font-size: 18px;
        line-height: 30px;
    }
    .media_title.bord-b{
        border: 1px solid #e9e9e9;
        border-top: 0;
        border-radius: 0 0 8px 8px;
    }
    .media_title.bord-t{
        border-bottom: 0;
        border-radius: 8px 8px 0 0;
        background: #fff;
    }
    .video_wpr{
        line-height: 0;
    }
    .video_wpr img{
        border-radius: 8px;
    }
    .video_wpr.b-round img{
        border-radius:8px;
    }
    .video_wpr.t-round img{
        border-radius: 8px;
    }
    .button_list button {
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        color: #d4d4d4;
        background: #2c3e50;
        padding: 15px 30px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        width: 100%;
        font-family: 'Inter', sans-serif;
    }
    .cell .left_side .button_list button {
        margin-bottom: 30px;
        padding: 10px 20px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        border-radius: 5px;
        height: 45px;
        font-family: 'Inter', sans-serif;
    }
    .dashboard_content p.para {
        font-size: 16px !important;
        line-height: 32px !important;
        color: #0e101a;
        font-weight: 400;
        font-family: 'Inter', sans-serif;
        padding: 0;
    }
    .cell .dashboard_content p.para{
        font-size: 16px !important;
        line-height: 32px !important;
    }
    .dashboard_content .edit_section{
        padding: 30px;
        margin-bottom: 45px;
        background: #fff;
        border-radius: 8px;
    }
    .cell .dashboard_content .edit_section{
        padding: 20px;
    }
    .edit_section .quest_wpr{
        padding: 25px 30px 20px 30px;
        position: relative;
        margin-bottom: 25px;
        border: 1px solid #edecec;
        border-radius: 6px;
    }
    .cell .edit_section .quest_wpr{
        padding: 15px 20px 10px 20px;
    }
    .edit_section .quest_wpr:last-of-type{
        margin-bottom: 10px;
    }
    .edit_section .quest_wpr h5{
        display: flex;
        color: #0E101A;
        font-size: 16px;
        line-height: 32px;
        font-weight: 400;
    }
    .edit_section .quest_wpr .q_index{
        flex: 0 0 18px;
        height: 18px;
        border-radius: 50%;
        color: #fff;
        background: #2c3e50;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 9px;
        line-height: 16px;
        margin: 7px 10px 0 0;
    }
    .edit_section .quest_wpr .question-options{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 0 5px;
    }
    .edit_section .quest_wpr .question-options li {
        display: flex;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 20px;
        color: #5a5a5a;
    }
    .edit_section .quest_wpr .question-options li .radio_box{
        border: 1px solid #2c3e50;
        width: 13px;
        height: 13px;
        flex-shrink: 0;
        border-radius: 50%;
        position: relative;
        display: block;
        margin: 3px 10px 0 0;
    }
    .edit_section .quest_wpr .question-options li p{
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
    }
    .edit_section .quest_wpr .field_wpr textarea{
        height: 80px;
    }
    .submit_btn .primary_btn, .get_start .primary_btn{
        height: 75px;
        font-size: 22px;
        line-height: 27px;
        padding: 0 15px;
        margin-top: 45px;
        letter-spacing: 1px;
        cursor: pointer;
    }
    .tab .submit_btn .primary_btn, .tab .get_start .primary_btn{
        height: 65px;
        font-size: 19px;
    }
    .cell .submit_btn .primary_btn, .cell .get_start .primary_btn{
        height: 55px;
        font-size: 16px;
        line-height: 20px;
    }
    .product_card {
        border-radius: 8px;
        overflow: hidden;
        padding: 0;
        line-height: 0;
        background: #fff;
    }

    .product_card h3 {
        padding: 20px 30px;
        margin: 0;
    }
    .cell .product_card h3 {
        padding: 10px 15px;
        margin: 0;
    }

    .product_card img {
        width: 100%;
        object-fit: cover;
    }
    .tab .product_card img, .cell .product_card img {
        width: 100%;
        object-fit: cover;
    }

    .nav {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        align-items: center;
        margin-right: 20px;
        padding-right: 20px;
        border-right: 1px solid #e9e9e9;
    }
    .cell .nav{
        margin-right: 10px;
        padding-right: 10px;
    }

    .nav .logo_img {
        max-width: 100%;
        height: auto;
        max-height: 30px;
        width: auto;
    }

    .cell .logo_img {
        max-width: 100%;
        height: auto;
        max-height: 25px;
    }

    :deep(.cell .dashboard_footer) {
        width: 100%;
        text-align: center;
        padding: 20px 0;
    }

    :deep(.cell .dashboard_footer ul li) {
        padding: 2px;
    }

    :deep(.cell .dashboard_footer ul li a) {
        width: 20px;
        height: 20px;
        border: 1px solid #2f7eed;
        font-size: 10px;
        color: #2f7eed;
        text-decoration: none;
    }

    :deep(.cell .dashboard_footer h4) {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0 5px;
    }

    :deep(.cell .dashboard_footer p) {
        font-size: 9px;
        line-height: 11px;
    }

    :deep(.dashboard_footer) {
        margin-top: auto;
        padding: 40px 0;
        background-color: #f5f5f5;
    }

    .tab .mobile_menu{
        width: 100%;
        display: flex;
        padding: 0 15px 20px 15px;
    }
    .cell .mobile_menu{
        width: 100%;
        display: flex;
        padding: 0 0 20px 0;
    }
    .cell .mobile_menu li {
        font-size: 13px;
        line-height: 16px;
    }
    .slide_box_container{
        height: 610px;
        position: absolute;
        top: 60px;
        bottom: 80px;
        left: 15px;
        right: 15px;
        overflow: hidden;
        z-index: 3;
    }
    .desk .slide_box_container{
        display: none;
    }
    .tab .slide_box_container{
        height: 875px;
        top: 70px;
        right: 20px;
        left: 20px;
        bottom: 105px;
    }
    .slide_box {
        padding: 20px;
        border-radius: 20px 20px 0 0;
        background: #fff;
        position: absolute;
        left: 1px;
        right: 1px;
        bottom: -100%;
        max-height: 430px;
        transition: all .3s ease-in-out;
        box-shadow: 0 -2px 30px rgba(0,0,0,.15);
        text-align: left;
        z-index: 3;
        overflow-y: auto;
    }
    .tab .slide_box{
        padding: 30px;
        max-height: 570px;
    }
    .slide_box::-webkit-scrollbar{
        width: 4px;
    }
    .slide_box::-webkit-scrollbar-thumb{
        background: #dbdbdb;
        border-radius: 2px;
    }
    .slide_box.active{
        bottom: 1px;
    }
    .slide_box .close_btn{
        position: sticky;
        float: right;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        background: rgba(50,55,59,.6);
        color: #fff;
        cursor: pointer;
    }
    :deep(.slide_box .instructor){
        padding-bottom: 15px;
    }
    :deep(.slide_box .instructor p){
        font-size: 15px;
        line-height: 27px;
        min-height: 27px;
        font-weight: 300;
        color: #0E101A;
    }
    :deep(.slide_box .instructor p:empty){
        min-height: 27px;
    }
    .slide_box .step_listing h3 {
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        padding: 0;
    }
    .slide_box .step_listing ul {
        list-style-type: none;
        max-height: 352px;
        overflow-y: scroll;
        margin-top: 20px;
    }
    .slide_box .step_listing ul::-webkit-scrollbar{
        display: none;
    }
    .slide_box .step_listing .step_card{
        border: 1px solid #e9e9e9;
        padding: 0;
        margin-bottom: 25px;
    }
    .slide_box .step_listing .step_card .items{
        display: flex;
        flex-grow: 1;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        overflow: hidden;
    }
    .slide_box .step_listing .step_card .thumb{
        flex: 0 0 106px;
        position: relative;
    }
    .tab .slide_box .step_listing .step_card .thumb{
        flex: 0 0 124px;
    }
    .slide_box .step_listing .step_card .step_info{
        height: 71px;
        padding: 5px 12px;
    }
    .slide_box .step_listing .step_card .step_info h5{
        font-size: 11px;
        line-height: 13px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 3px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .tab .slide_box .step_listing .step_card .step_info h5{
        font-size: 13px;
        line-height: 17px;
        margin-bottom: 5px;
    }
    .slide_box .step_listing .step_card .step_info p{
        font-size: 9px;
        line-height: 11px;
        font-weight: 400;
        color: #5a5a5a;
        max-height: 22px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .tab .slide_box .step_listing .step_card .step_info p{
        font-size: 11px;
        line-height: 14px;
    }
    .slide_box .step_listing .step_lock{
        border-radius: 5px;
    }
    .slide_box .product_card{
        width: 100%;
        overflow: visible;
        border: 0;
        border-radius: 0;
        margin: 0;
        padding-bottom: 15px;
    }
    .slide_box .product_card h3{
        padding: 0;
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        margin-bottom: 15px;
    }
    .slide_box .progress_sec{
        margin-bottom: 30px;
    }
    .slide_box .progress_sec h3{
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
    }
</style>
